import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const button_xs = "text-xs px-4 py-1.5 ";
const button_sm = "sm:text-sm sm:px-5 sm:py-3 ";
const button_md = "md:text-lg md:font-medium md:px-10 md:py-4";
const buttonPadding = button_xs + button_sm + button_md;

const Statement = ({ children }) => {
  return (
    <div className="text-xs sm:text-sm md:text-lg font-light w-11/12 md:w-4/5 mx-auto">
      <div className="max-w-full table mx-auto text-left">{children}</div>
    </div>
  );
};

const LinkButton = (props) => {
  return (
    <div className="border sm:border-2 md:border-4 mx-auto bg-white border-darkblue max-w-min hover:bg-blue-50">
      <Link
        to={props.to}
        className={"whitespace-nowrap block " + buttonPadding}
      >
        {props.children}
      </Link>
    </div>
  );
};

const BlueLinkButton = (props) => {
  return (
    <div className="border-0 mx-auto text-white bg-paleblue max-w-min hover:bg-blue-400">
      <Link
        to={props.to}
        className={"whitespace-nowrap block " + buttonPadding}
      >
        {props.children}
      </Link>
    </div>
  );
};

const IndexPage = () => {
  return (
    <Layout>
      <div class="container mx-auto">
        <div class="mx-auto text-center relative">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            interval={5000}
          >
            <div>
              <StaticImage src="../images/slider/slider1.jpg" />
            </div>
            <div>
              <StaticImage src="../images/slider/slider2.jpg" />
            </div>
            <div>
              <StaticImage src="../images/slider/slider3.jpg" />
            </div>
            <div>
              <StaticImage src="../images/slider/slider4.jpg" />
            </div>
          </Carousel>

          {/* COMPANY */}
          <div class="relative mx-auto flex content-center -mt-1">
            <div>
              <StaticImage
                src="../images/honeycomb.png"
                alt="Honeycomb"
                class="w-full h-full"
              />
            </div>
            <div
              class="absolute w-full top-0 text-2xl md:text-huge font-bold text-darkblue"
              style={{ marginTop: "11%" }}
            >
              造ることの思考集団
            </div>
            <div
              class="absolute top-0 w-full text-xs md:text-xl font-semibold text-secondary tracking-widest"
              style={{ marginTop: "19%" }}
            >
              C O M P A N Y
            </div>

            <div class="absolute top-0 w-full" style={{ marginTop: "25%" }}>
              <Statement>
                お客様が「何を求め」「どのような装置」で「どう改善する」のか、社員一同が常に考える企業であり続けています。
              </Statement>
            </div>

            <div class="absolute w-full top-0" style={{ marginTop: "35.5%" }}>
              <LinkButton to={"/company"}>詳しくはこちら</LinkButton>
            </div>
          </div>

          {/* SERVICE */}
          <div
            class="relative mx-auto flex content-center z-10"
            style={{ marginTop: "-23%" }}
          >
            <StaticImage
              src="../images/globe.png"
              alt="Globe"
              class="w-full h-full"
            />

            <div
              class="absolute top-0 text-white w-full"
              style={{ marginTop: "18%" }}
            >
              <div class="text-2xl md:text-huge font-semibold text-center">
                TACで出来ること
              </div>
            </div>

            <div
              class="absolute top-0 w-full text-xs md:text-xl font-semibold text-white tracking-widest"
              style={{ marginTop: "26.5%" }}
            >
              S E R V I C E
            </div>

            <div
              class="absolute top-0 w-full text-white"
              style={{ marginTop: "31.5%" }}
            >
              <Statement>
                自動化システム装置の設計・製造から、お客様の要望に合わせたサービスを提供します。
              </Statement>
            </div>

            <div class="absolute w-full top-0" style={{ marginTop: "41%" }}>
              <BlueLinkButton to="/service">詳しくはこちら</BlueLinkButton>
            </div>
          </div>

          {/* RECRUIT */}
          <div
            class="relative mx-auto flex content-center"
            style={{ marginTop: "-11%" }}
          >
            <StaticImage src="../images/triangle.png" alt="Triangle" />

            <div class="absolute top-0 w-full" style={{ marginTop: "27%" }}>
              <div class="text-2xl md:text-huge font-semibold text-darkblue text-center">
                採用情報
              </div>
            </div>
            <div
              class="absolute top-0 w-full text-xs md:text-xl font-semibold text-secondary tracking-widest"
              style={{ marginTop: "35%" }}
            >
              R E C R U I T
            </div>

            <div
              class="absolute top-0 w-full text-lg font-light"
              style={{ marginTop: "40%" }}
            >
              <Statement>
                私たちと一緒にお客様に喜んでいただくことを考える「思考集団」のメンバーを募集しています。
              </Statement>
            </div>

            <div class="absolute top-0 w-full" style={{ marginTop: "50%" }}>
              <LinkButton to="/recruit">採用情報はこちら</LinkButton>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
